.Prompt {
  /* animation: oscillate 300ms ease-in-out infinite, turn-on var(--turn-on-time) ease-in; */
  opacity: 1;
  transform-origin: center;
  z-index: 1;
  height: 100%;
  text-shadow: 0 0 8px rgb(115, 201, 115);
  font-size: 20px;
}

.Prompt .__header {
  max-width: 50vw;
  padding: 0;
  text-align: center;
}

@media only screen and (max-width: 76.8rem) {
  .Prompt .__header {
    padding: 0 2rem;
    max-width: inherit;
  }
}

@keyframes oscillate {
  0% {
    opacity: 0.88;
  }
  15% {
    opacity: 0.92;
  }
  60% {
    opacity: 0.9;
  }
  80% {
    opacity: 0.96;
  }
  100% {
    opacity: 0.88;
  }
}

@keyframes turn-on {
  0% {
    opacity: 0;
    background: green;
    transform: skewX(88deg) scale(0);
  }
  30% {
    opacity: 1;
  }
  100% {
    background: transparent;
    transform: skewX(0) scale(1);
  }
}
