h1 {
	font-size: 4.2rem;
	text-align: center;
}
h2 {
	font-size: 2.5rem;
}

a {
	color: white;
	text-decoration: none;
}

a:hover,
a:focus,
a:active {
	color: lighten(#39fcff, 20%);
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

pre {
	margin: 0;
}

@media (max-width: 76.8rem) {
  .iwtcot-banner code {
		font-size: 1.8vw;
	}
	pre.iwtcot-banner,
	pre.iwtcot-banner code,
	pre.iwtcot-banner code br{
		line-height: 1.6vw;
	}
}


@media (max-width: 40rem) {
	.iwtcot-banner code {
    font-size: 2vw;
  }
	pre.iwtcot-banner,
	pre.iwtcot-banner code,
	pre.iwtcot-banner code br{
		line-height: 1.6vw;
	}
}

