.Console {
	position: fixed;
	z-index: -1;
	height: 100%;
	width: 100%;
	overflow: hidden;
}
.Console .__crt {
	content: "";
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAALElEQVQYV2NkYGD4/x9IMAIxAxoDmzhIHfU0gCxFB9S1AacfsFmNJQzAoQIAH84j/OCkeYUAAAAASUVORK5CYII=');
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.1;
	z-index: 3;
}
.Console .__line {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height: .1rem;
	box-shadow: 0 0 .6rem #9f9;
	background-color: #6e6;
	opacity: 0.3;
	top: 0;
	animation: linescan 20s linear infinite;
	animation-delay: var(--turn-on-time);
	z-index: 3;
}
.Console .__glare {
	filter: blur(8px);
	width: 100%;
	height: 100%;
	position: absolute;
	background: linear-gradient(135deg, rgba(1, 3, 3, 0.25) 39%, transparent 100%);
	border-radius: 50% 0 0 0;
}
.Console canvas {
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	position: absolute;
	z-index: 1;
	opacity: 0.3;
}

@keyframes linescan {
	from {
		top: 0;
	}
	to {
		top: 99%;
	}
}
