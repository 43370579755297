.Shows {
	z-index: 1;
}

.Shows .__item i {
	color: white;
	padding: 0 .4rem;
}
.Shows .__item {
	margin-bottom: 1rem;
	line-height: 1.2;
}
