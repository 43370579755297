.Navigation {
  width: 100%;
  background: var(--menu-background);
  z-index: 999;
}
.Navigation .__button {
  display: none;
}
.Navigation .__item.--highlight a {
  color: var(--menu-background);
}
.Navigation .__item.--highlight{
  background: var(--text-green);
}
.Navigation .__list {
  display: flex;
  list-style: none;
  justify-content: space-around;
  margin: 0;
  padding: 2rem;
  font-size: 1.6rem;
}

@media only screen and (max-width: 76.8rem) {
  .Navigation {
    position: fixed;
    width: 40vw;
    right: calc(var(--menu-sliver) - 40vw);
    height: 100vh;
    transition: all 400ms ease-in-out;
  }
  .Navigation[data-active='true'] {
    right: 0;
  }
  .Navigation .__list {
    flex-wrap: column;
    flex-wrap: wrap;
    padding: 0;
    padding-top: 2rem;
  }
  .Navigation .__divider {
    display: none;
  }
  .Navigation .__item {
    display: flex;
    flex-basis: 100%;
    flex-grow: 1;
    line-height: 2;
  }
  .Navigation .__item i {
    width: var(--menu-sliver);
    text-align: center;
  }
  .Navigation .__button {
    display: block;
    background: transparent;
    border: none;
    color: var(--text-green);
    margin: 0;
    padding: 0;
    font-family: inherit;
    line-height: 2;
  }
}

@media only screen and (max-width: 40rem) {
  .Navigation {
    position: fixed;
    width: 60vw;
    right: calc(var(--menu-sliver) - 60vw);
    height: 100vh;
    transition: all 400ms ease-in-out;
  }
}