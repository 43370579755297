.Loading {
	width: 100%;
	height: 20rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.Loading svg rect:nth-child(1) {
	fill: #ff0087;
}
.Loading svg rect:nth-child(2) {
	fill: #ff39a2;
}
.Loading svg rect:nth-child(3) {
	fill: #ff57b0;
}

.Loading svg rect:nth-child(4) {
	fill: #ff71bc;
}
