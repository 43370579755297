* {
  box-sizing: border-box;
}

html,
body,
main {
  height: 100%;
}
html {
  font-size: 10px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.8em;
  font-family: var(--body-font);
  background: var(--menu-background);
  color: var(--text-green);
}
.__capsule {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  margin: auto;
  max-width: 133rem;
}

@media only screen and (max-width: 76.8rem) {
  .Container {
    width: calc(100vw - var(--menu-sliver));
    word-break: break-word;
  }
}
