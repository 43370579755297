:root {
  /* Colors */
  --slate: #282828;
  --grey: #666;
  --light-grey: #ddd;
  --red: #c6452b;
  --menu-background: #161512;
  --background-light: #f8f7f2;

  /* Text */
  --text-slate: #333;
  --text-grey: #888;

  --dark-link: #ff39a1;

  --green: #2aff20;
  --text-green: #b3f7b5;
  --lightgreen: #8fc49c;

  --blue: #39fcff;
  --laser: #ff39a1;

  --component-padding: 2.4rem 3.2rem 1rem;
  /* Typography */
  --body-font: "VT323", monospace;

  /* Responsive */
  --mobile-size: 760px;
  --menu-sliver: 4rem;

  /* Animiation */
  --turn-on-time: 600ms;
}
