.PressKit {
  font-family: "Sans Source Pro", Arial, Helvetica, sans-serif;
  background-color: var(--background-light);
  background-image: url(../geometry_@2X.png);
  background-size: 66.6rem;
  background-repeat: repeat;
  color: var(--text-slate);
  border-bottom: none;
}
.PressKit.__capsule {
  max-width: 600px;
  margin: auto;
}
.PressKit p {
  line-height: 1.2;
}
.PressKit a {
  color: var(--dark-link);
}
.PressKit .iframe__container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}
.PressKit .iframe__container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.PressKit img {
  display: block;
  width: 100%;
  border: 1rem solid white;
  box-sizing: border-box;
  margin: 3rem auto;
  max-width: 60rem;
  transform: rotate(-2deg);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.PressKit .__section {
  margin: 4rem 0;
  max-width: 84rem;
}
.PressKit .__section.--link {
  margin: 0.4rem 0;
}

.PressKit .__section br {
  display: block;
  margin: 0.6rem;
}

.PressKit .__download-link {
  position: relative;
  display: flex;
}

.PressKit .__download-link .--hover {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  flex: 1 1 0;
  background-color: var(--text-green);
}

@media only screen and (max-width: 76.8rem) {
  .PressKit {
    box-shadow: none;
    border: none;
  }
}
